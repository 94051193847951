/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import Layout from '../components/layout';
import Container from '../components/Container';
import FullHeightColumn from '../components/FullHeightColumn';
import * as api from '../utils/api';
import delay from '../utils/delay';
import { resetBasket } from '../state/basket';
import { locNavigate, prefetchPage } from '../state/session';
import { setOrderId } from '../utils/order';
import { isBrowser } from '../utils';
import Spinner from '../components/Spinner';
import 'url-search-params-polyfill'; // for IE
import * as analytics from '../utils/analytics';
import { PAGE } from '../state/customsFlow';
import { STEP } from '../home-delivery/utils';
import { ReturnContext } from '../returnShipment/ReturnContext';

const pollInterval = 1000.0; // todo
const maxOrderWaitTime = 90; // todo

export default ({ location, pageContext }) => {
  analytics.usePageCategory('paketit');
  const localize = useSelector(state => state.localize);
  const translate = getTranslate(localize);
  const dispatch = useDispatch();
  const searchParams = isBrowser && new URLSearchParams(location.search);
  const orderId = searchParams && searchParams.get('orderId');
  const transactionId = searchParams && searchParams.get('transactionId'); // parameter added by Netaxept to redirect url
  const responseCode = searchParams && searchParams.get('responseCode');
  const [errorMsg, setError] = useState();
  const [startTime] = useState(+new Date());

  useEffect(() => {
    if (!orderId || responseCode !== 'OK') {
      dispatch(locNavigate('/ostoskori'));
      return;
    } else {
      // save orderId to sessionStorage just in case
      setOrderId(orderId, transactionId);
      dispatch(resetBasket()); // remove basket from redux, since it can't be modified after payment
    }
  }, []);

  const componentIsMounted = useRef(true);
  useEffect(() => {
    componentIsMounted.current = true;
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const { setOrderPayed: setReturnOrder } = useContext(ReturnContext);
  const checkStatus = async (orderId, transId) => {
    const { state, type, ...order } = await api.getOrderStatus(orderId, transId);
    if (['AWAITING_PAYMENT', 'PROCESSING'].includes(state)) {
      const now = +new Date();
      const elapsed = (now - startTime) / 1000;
      if (elapsed > maxOrderWaitTime) {
        const message = translate('buyParcel.payment.error', { orderId });
        setError(message);
        return;
      }
      await delay(pollInterval);
      if (componentIsMounted.current) {
        checkStatus(orderId, transId);
      } else {
        console.log('check component no longer mounted');
      }
    } else if (state === 'COMPLETED') {
      if (type === 'delivery') {
        dispatch(locNavigate('/delivery', '', { state: { order } }));
      } else if (type === 'storagetime') {
        dispatch(locNavigate('/storage-time', '', { state: { order } }));
      } else if (type === 'return') {
        setReturnOrder(order);
        dispatch(locNavigate('/return-shipment', '', { state: { order } }));
      } else if (type === 'transfer') {
        dispatch(locNavigate('/transfer', '', { state: { order } }));
      } else if (type === 'customs') {
        dispatch(
          locNavigate('/customs', `?parcelNumber=${order.shipmentNumber}`, {
            state: { order, page: PAGE.THANKS },
          })
        );
      } else if (type === 'upgrade') {
        dispatch(locNavigate('/upgrade-size', '', { state: { order } }));
      } else if (type == 'woltdelivery') {
        if (order?.details) {
          dispatch(locNavigate('/wolt-delivery', '', { state: { order } }));
        } else {
          dispatch(locNavigate('/sovijakelu-en', '', { state: { order, step: STEP.WOLT_PAYMENT_OK } }));
        }
      } else if (type == 'returnToCustomer') {
        dispatch(
          locNavigate(
            '/return-to-customer',
            `?shipment=${order.returnToCustomer.shipment.shipmentNumber}&token=${order.returnToCustomer.shipment.shipmentToken}`,
            {}
          )
        );
      } else {
        dispatch(locNavigate('/vahvistus', '', { state: { order } }));
      }
    } else if (state === 'PAYMENT_FAILED') {
      const message = translate('buyParcel.payment.failed', { orderId });
      setError(message);
    } else if (state === 'ERROR') {
      const message = translate('buyParcel.payment.error', { orderId });
      setError(message);
    } else {
      console.warn('Unexpected order state', state);
    }
  };

  useEffect(() => {
    if (responseCode !== 'OK') {
      return;
    }
    api
      .completeOrder(orderId, transactionId)
      .then(() => {
        checkStatus(orderId, transactionId);
      })
      .catch(err => {
        console.log('complete order failed', err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  dispatch(prefetchPage('/vahvistus'));

  return (
    <Layout paths={pageContext.paths} locale={pageContext.locale || 'en'} showNavigation={false}>
      <Container>
        <FullHeightColumn>
          {errorMsg ? (
            <div sx={{ color: 'pinkDark', mt: [3] }}>{errorMsg}</div>
          ) : (
            <div>
              <span>{translate('buyParcel.payment.processing')}</span>
              <span>{translate('buyParcel.payment.processing2')}</span>
              <Spinner />
            </div>
          )}
        </FullHeightColumn>
      </Container>
    </Layout>
  );
};
